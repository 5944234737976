<template>
  <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card" :class="cardClass" @click="action">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <div v-if="data.provider_name" class="py-2 pl-6">
            {{ data.provider_name }} (<span class="store-font-color">NT$ {{ data.price }}</span>) / {{ $helper.orderTimeFormat(data.updated_at)
            }} <span class="store-font-color">{{ status.text }}</span>
          </div>
        </v-col>

        <v-col cols="12" sm="9">
          <div class="py-2 pl-6">訂單日期： <template v-if="status.is_done">{{ status.paidRange[0] + ' ~ ' +
            status.paidRange[1]
          }}</template> <template v-else>{{ $helper.orderTimeFormat(data.created_at) }}</template></div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="py-2 text-center item-card-btn pointer">
            {{ actionText }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

import Moment from 'moment'

export default {
  props: {
    type: {
      type: String,
      default: "secondary",
      validator(value) {
        return ["primary", "secondary", 'lighten', 'dark', 'error'].includes(value);
      },
    },
    data: {
      default: () => [{ text: "label", value: "value" }],
    },
    actionText: {
      type: String,
    },
    action: {
      type: Function,
      default: () => { },
    },
  },
  computed: {
    status() {
      const data = this.data
      const doneOrdersDate = data.orders.filter(item => item.status === 'finished').map(item => item.updated_at
      ).sort()
      const fistDate = Moment(doneOrdersDate[0]).format('YYYY-MM-DD')
      const lastDate = Moment(doneOrdersDate[doneOrdersDate.length - 1]).format('YYYY-MM-DD')
      const is_done = doneOrdersDate.length === data.orders.length
      const res = {
        is_done: is_done,
        text: data.status == 'pending' ? '尚未付款' : '付款完成',
        paidRange: is_done ? [fistDate, lastDate] : '',
        paidDateTime: is_done ? doneOrdersDate[0] : '',
      };
      return res;
    },
    cardClass() {
      if (this.type === "primary") return "item-card--primary";
      if (this.type === "secondary") return "item-card--secondary";
      if (this.type === "lighten") return "item-card--lighten";
      if (this.type === "dark") return "item-card--dark";
      if (this.type === "error") return "item-card--error";
      return "";
    },
  },
};
</script>

<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #009775
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #009775
      border: 1px solid #009775
.provider-store-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #006DE0
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #006DE0
      border: 1px solid #006DE0

.item-card--lighten
  .item-card-btn
    background: #ffffff
    color: #00E086
    border: 1px solid #00E086
.item-card--dark
  .item-card-btn
    background: #ffffff
    color: #122A47
    border: 1px solid #122A47
.item-card--error
  .item-card-btn
    background: #ffffff
    color: #E00000
    border: 1px solid #E00000
</style>